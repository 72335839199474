
.ConfigScreen {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	text-align: center;
	color: white;
	font-family: Helvetica;
}

.ConfigScreen img{
  width: 60%;
  margin: 2vw auto;
  display: block;
	height: auto;
}

.chooseYourColor {
	margin-top: 8vw;
}

.ConfigScreen h1{
	color: white;
	text-align: center;
	pointer-events: none;
	margin-bottom: 10px;
}

ul{
	text-align: center;
	padding: 0;
}
li{
	display: inline;
	margin-right: 10px;
	font-size: 24px;
}

p {
	margin: 0;
}

.circle{
	border-radius: 50%;
	width: 3vw;
	height: 3vw;
	margin: 0.5vw;
	display: inline-block;
}

.activePlayerList {
	text-align: center;
}
