
.PauseScreen{
	position: absolute;
	width: 100vw;
	height: 100vh;
	font-family: Helvetica;
}

.wins{
	font-size: 18px;
  min-width: 24px;
  border-radius: 100%;
  height: 24px;
  line-height: 24px;
  margin: 0 auto;
  display: inline-block;
}

.keys {
	font-family: monospace;
	font-size: 12px;
}

li {
	display: inline-block;
	width: 40px;
}
.PauseScreen h1 {
	position: absolute;
	top: 40vh;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 40px;
}

.PauseScreen h2, .PauseScreen h3 {
	text-align: center;
	color: white;
}

.continue {
	position: absolute;
	bottom: 10vh;
	left: 0;
	right: 0;
}