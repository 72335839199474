
.ConfigScreen {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	text-align: center;
	color: white;
	font-family: Helvetica;
}

.ConfigScreen img{
  width: 60%;
  margin: 2vw auto;
  display: block;
	height: auto;
}

.chooseYourColor {
	margin-top: 8vw;
}

.ConfigScreen h1{
	color: white;
	text-align: center;
	pointer-events: none;
	margin-bottom: 10px;
}

ul{
	text-align: center;
	padding: 0;
}
li{
	display: inline;
	margin-right: 10px;
	font-size: 24px;
}

p {
	margin: 0;
}

.circle{
	border-radius: 50%;
	width: 3vw;
	height: 3vw;
	margin: 0.5vw;
	display: inline-block;
}

.activePlayerList {
	text-align: center;
}


.PauseScreen{
	position: absolute;
	width: 100vw;
	height: 100vh;
	font-family: Helvetica;
}

.wins{
	font-size: 18px;
  min-width: 24px;
  border-radius: 100%;
  height: 24px;
  line-height: 24px;
  margin: 0 auto;
  display: inline-block;
}

.keys {
	font-family: monospace;
	font-size: 12px;
}

li {
	display: inline-block;
	width: 40px;
}
.PauseScreen h1 {
	position: absolute;
	top: 40vh;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 40px;
}

.PauseScreen h2, .PauseScreen h3 {
	text-align: center;
	color: white;
}

.continue {
	position: absolute;
	bottom: 10vh;
	left: 0;
	right: 0;
}


.StartScreen{
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.StartScreen canvas{
	width: 100%;
	height: auto;
}

.StartScreen h2{
	position: absolute;
	bottom: 10vh;
	width: 100%;
	text-align: center;
	font-size: 1.5vw;
	color: white;
	cursor: pointer;
}

.StartScreen h1{
	position: absolute;
	top: 25vh;
	width: 100%;
	text-align: center;
	color: white;
	font-size: 10vw;
	pointer-events: none;
}

.StartScreen .menu-item{
	font-size: 1vw;
	position: absolute;
	bottom: 1vw;
	right: 1vw;
	color: white;
	text-decoration: none;
}

.StartScreen .menu-item:hover{
	text-decoration: underline;
}

.imprint{
	background-color: white;
	color: black;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imprint .container {
	padding: 30px;
	width: 35vw;
  color: black;
  background-color: white;
}

.imprint a {
	color: black;
	cursor: pointer;
}

.imprint .close {
	float: right;
	font-size: 20px;
}

.imprint a:hover {
	color: #ff0000;
}

.imprint p{
	margin: 0;
}

.imprint h3{
	margin-top: 0;
}


canvas{
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	display: block;
	background-color: black;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background-color: black;
  overflow: hidden;
}

.blink {
  opacity: 0;
  -webkit-animation: blinking 1s linear infinite;
          animation: blinking 1s linear infinite;
}

@-webkit-keyframes blinking {
  from,
  49.9999% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}

@keyframes blinking {
  from,
  49.9999% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}

.back {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: white;
}


