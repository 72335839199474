canvas{
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	display: block;
	background-color: black;
}
