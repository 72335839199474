body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background-color: black;
  overflow: hidden;
}

.blink {
  opacity: 0;
  animation: blinking 1s linear infinite;
}

@keyframes blinking {
  from,
  49.9999% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}

.back {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: white;
}

