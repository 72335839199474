

.StartScreen{
	position: absolute;
	width: 100vw;
	height: 100vh;
}

.StartScreen canvas{
	width: 100%;
	height: auto;
}

.StartScreen h2{
	position: absolute;
	bottom: 10vh;
	width: 100%;
	text-align: center;
	font-size: 1.5vw;
	color: white;
	cursor: pointer;
}

.StartScreen h1{
	position: absolute;
	top: 25vh;
	width: 100%;
	text-align: center;
	color: white;
	font-size: 10vw;
	pointer-events: none;
}

.StartScreen .menu-item{
	font-size: 1vw;
	position: absolute;
	bottom: 1vw;
	right: 1vw;
	color: white;
	text-decoration: none;
}

.StartScreen .menu-item:hover{
	text-decoration: underline;
}

.imprint{
	background-color: white;
	color: black;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imprint .container {
	padding: 30px;
	width: 35vw;
  color: black;
  background-color: white;
}

.imprint a {
	color: black;
	cursor: pointer;
}

.imprint .close {
	float: right;
	font-size: 20px;
}

.imprint a:hover {
	color: #ff0000;
}

.imprint p{
	margin: 0;
}

.imprint h3{
	margin-top: 0;
}

